const data = [
    {
      id: 1,
      question: "Why did you start to learn programming?",
      answer: "I have been interested in programming for since I was a teenager and decided 3 years ago to start learning."
    },
    {
      id: 2,
      question: "Where did you start learning programming?",
      answer: "I started with a full stack developer course and at the same time I started a bachelor's degree program in computer technology with a concentration in web development."
    },
    {
      id: 5,
      question: "What programming languages and framworks are you most comfortable in?",
      answer: "HTML, CSS, Javascript, Reactjs, bootstrap and Java"
    },
    {
      id: 4,
      question: "What programming languages and framworks are you familar with?",
      answer: "PHP, Flutter, SQL, ExpressJS, NodeJS"
    },
    {
      id: 3,
      question: "What do you enjoy most about programming in general?",
      answer: "I honestly love learning something new about the various programming langauges and frameworks that I use but what I enjoy more than that is figuring out something and making it work which is a big part of coding."
    },
    {
      id: 6,
      question: "What are your goals in the tech industry",
      answer: "To be part of a team of motivated programmers and work together to make the experience of a site and or app better for the user."
    }
  ]

  export default data