// import Card from '../../components/Card'

// const Project = ({project}) => {
//   return (
//     <Card className="portfolio__project">
//         <div className="portfolio__project-image">
//             <img src={project.image} alt="Portfolio Project Img" />
//         </div>
//         <h4>{project.title}</h4>
//         <p>{project.desc}</p>
//         <div className="portfolio__project-cta">
//             <a href={project.demo} className="btn sm" target="_blank" rel="noopener noreferrer">Demo</a>
//             <a href={project.github} className="btn sm primary" target="_blank" rel="noopner noreferrer">Github</a>
//         </div>
//     </Card>
//   )
// }

// export default Project
import React, { useState } from "react";
import Card from "../../components/Card";

const Project = ({ project }) => {
  const [showDetails, setShowDetails] = useState(false);

  const toggleDetails = () => {
    setShowDetails(!showDetails);
  };

  const closeDetails = () => {
    setShowDetails(false);
  };

  return (
    <Card className="portfolio__project">
      <div className="portfolio__project-image">
        <img src={project.image} alt="Portfolio Project Img" />
      </div>
      <h4>{project.title}</h4>
      {showDetails ? (
        <div>
          <p>{project.desc}</p>
          <div className="portfolio__project-cta">
            <a
              href={project.demo}
              className="btn sm"
              target="_blank"
              rel="noopener noreferrer"
            >
              Demo
            </a>
            <a
              href={project.github}
              className="btn sm primary"
              target="_blank"
              rel="noopner noreferrer"
            >
              Github
            </a>
            <button onClick={closeDetails} className="btn sm">
              Close
            </button>
          </div>
        </div>
      ) : (
        <div>
          <p>{project.desc.substring(0, 200)}...</p>
          <button onClick={toggleDetails} className="btn sm">
            More Details
          </button>
        </div>
      )}
    </Card>
  );
};

export default Project;

// In this code, we added the state showDetails and the toggleDetails function using the useState hook. Initially, showDetails is set to false, indicating that the full description is not visible.
// The project.desc is rendered conditionally based on the showDetails state. If showDetails is true, the full description is shown along with the buttons. Otherwise, the description is truncated using substring(0, 100) and an ellipsis (...), and a "More Details" button is displayed.
// Clicking the "More Details" button triggers the toggleDetails function, which toggles the showDetails state between true and false, revealing or hiding the expanded description accordingly.

// In the updated code, we added a closeDetails function that sets the showDetails state to false. Inside the conditional rendering, we added a "Close" button that triggers the closeDetails function when clicked.
// With this addition, when the user clicks on the "More Details" button and the expanded description is shown, they will also have the option to close it by clicking the "Close" button.
