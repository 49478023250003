import AboutImage from '../../assets/about.png'
import CV from '../../assets/Programmer.pdf'
import {HiDownload} from 'react-icons/hi'
import data from './data'
import Card from '../../components/Card'
import './about.css'

const About = () => {
  return (
    <section id="about" data-aos="fade-in">
        <div className="container about__container">
            <div className="about__left">
                <div className="about__portrait">
                    <img src={AboutImage} alt="About Img" />
                </div>
            </div>
            <div className="about__right">
                <h2>About Me</h2>
                <div className="about__cards">
                    {
                        data.map(item => (
                            <Card key={item.id} className="about__card">
                                <span className='about__card-icon'>{item.icon}</span>
                                <h5>{item.title}</h5>
                                <small>{item.desc}</small>
                            </Card>
                        ))
                    }
                </div>
                <p>
                Building projects, learning new concept, and applying those concepts are all things I enjoy as a programmer. I'm always motivated to do more and find ways to make a project better.
                </p>
                <p>
                Hi, my name is Gregory Douglas from California. I'm a full-stack web developer with a Bachelors degree in Computer Technology with a concentration in web development. My top priorities are to join a team of motivated programmers, work hard, and keeping the experience of a website and or app top-notch for users world-wide. Check out my resume below!
                </p>
                <a href={CV} download className='btn primary'>Download CV <HiDownload/></a>
            </div>
        </div>
    </section>
  )
}

export default About