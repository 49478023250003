import Image1 from '../../assets/project1.png'
import Image2 from '../../assets/project2.png'
import Image3 from '../../assets/project3.png'
import Image4 from '../../assets/project4.png'
import Image5 from '../../assets/project5.png'
import Image6 from '../../assets/project6.png'
import Image7 from '../../assets/project7.png'
import Image8 from '../../assets/project8.png'
import Image9 from '../../assets/project9.png'
const data = [
    {
        id: 1,
        category: 'frontend',
        image: Image1,
        title: "NewAgeUX",
        desc: "I utilized React.js, CSS, and HTML to craft a project/site called NewAgeUX, which revolves around a company specializing in website makeovers. With NewAgeUX, we bring forth the most imaginative and creative designs to transform your website into a masterpiece. By combining the power of React.js for dynamic and interactive components, CSS for stunning visual styling, and HTML for structuring the content, NewAgeUX offers professional-grade website transformations that breathe new life into your online presence. Let our team of experts at NewAgeUX give your website the makeover of a lifetime, ensuring it stands out with its captivating design and exceptional user experience..",
        demo: 'https://dougbug98.github.io/NewAgeUX/',
        github: 'https://github.com/dougbug98/NewAgeUX'
    },
    {
        id: 2,
        category: 'frontend',
        image: Image2,
        title: "Dear Tokyo Restuarant",
        desc: "Combining the power of React.js, CSS, and HTML, I meticulously developed Dear Tokyo Restaurant, a captivating site dedicated to showcasing and promoting the exquisite culinary delights that Japan has to offer. Prepare your taste buds for a tantalizing experience as you savor the finest seafood, succulent chicken, and the unparalleled quality of 100% grass-fed Wagyu beef. Our commitment to freshness ensures that each dish is bursting with flavor, leaving you craving for more. Immerse yourself in a serene and inviting environment, where every detail is thoughtfully crafted to create a soothing ambiance. At Dear Tokyo, we believe in creating not just meals, but cherished memories that you can share with your loved ones. Indulge in our fresh, mouth-watering dishes, and let us transport you to the heart of Japan's culinary delights..",
        demo: 'https://dougbug98.github.io/DearTokyo/',
        github: 'https://github.com/dougbug98/DearTokyo'
    },
    {
        id: 3,
        category: 'frontend',
        image: Image3,
        title: "HooBank",
        desc: "Utilizing React.js, CSS, and HTML, I created a site that offers a comprehensive credit card evaluation service. Our team of experts employs a meticulous methodology to identify the credit cards that best align with your specific needs. We carefully analyze factors such as annual percentage rates and annual fees to provide you with personalized recommendations. When it comes to choosing a bank, who better to trust than HooBank? Explore our site and discover the credit cards that perfectly suit your financial requirements.",
        demo: 'https://dougbug98.github.io/HooBank/',
        github: 'https://github.com/dougbug98/HooBank'
    },
    {
        id: 4,
        category: 'frontend/backend',
        image: Image4,
        title: "TheBlueCarpet(Netflix Clone)",
        desc: "TheBlueCarpet is a Netflix clone developed using HTML, CSS, PHP, JQuery, JavaScript, and MySQL. This project was created to demonstrate and hone web development skills while providing a functional streaming platform that mimics the popular Netflix site.",
        demo: 'https://thebluecarpet.net/register.php',
        github: 'https://github.com/dougbug98/TheBlueCarpet'
    },
    {
        id: 5,
        category: 'frontend',
        image: Image5,
        title: "Digital Clock",
        desc: "I created a user-friendly digital clock with an attractive design, title, and background image that displayed the current time on a website. I used JavaScript to retrieve the current time from the computer and displayed it digitally. The clock was synchronized with my computer's time, ensuring that it always displayed the correct time. This assignment helped me acquire valuable skills, such as working with JavaScript to retrieve the current time and designing a visually appealing website.",
        demo: 'https://dougbug98.github.io/Digital-Clock/',
        github: 'https://github.com/dougbug98/Digital-Clock'
    },
    {
        id: 6,
        category: 'frontend',
        image: Image6,
        title: "New Years Countdown",
        desc: "My endeavor involved developing a JavaScript New Year's countdown for 2024. I created a countdown timer that dynamically generated the numbers and retrieved the current date and time from my computer. Users were provided with an accurate representation of the time remaining until the start of 2024.",
        demo: 'https://dougbug98.github.io/New-Years-Countdown/',
        github: 'https://github.com/dougbug98/New-Years-Countdown'
    },
    {
        id: 7,
        category: 'frontend',
        image: Image7,
        title: "Analogue Clock",
        desc: "In my analog clock project, I created a Rolex-logoed clock that displayed the browser's time. JavaScript transformed the current time into degrees for the clock face. The circular clock face displayed the time with the Rolex emblem in the center. JavaScript updated the clock hands in real time. CSS was used to make the clock face, hands, and logo look professional. This project improved my JavaScript and CSS skills as we managed the DOM, Date object, and styled elements.",
        demo: 'https://dougbug98.github.io/Analogue-Clock/',
        github: 'https://github.com/dougbug98/Analogue-Clock'
    },
    {
        id: 8,
        category: 'frontend',
        image: Image8,
        title: "Month Calendar",
        desc: "For this project, I developed a month-long calendar using HTML, CSS, and JavaScript. The calendar had a header that displayed the current month and date. It included all the dates of the month from 1 to 30, and the current date was accurately displayed. Everything was generated dynamically and automatically updated based on the current date and month. The calendar was visually enticing with CSS-styled effects like shadow and other design elements.",
        demo: 'https://dougbug98.github.io/Month-Calendar/',
        github: 'https://github.com/dougbug98/Month-Calendar'
    },
    {
        id: 9,
        category: 'frontend',
        image: Image9,
        title: "Rotating Image Gallery",
        desc: "In the project, I created a compelling image gallery with rotating photos and two navigation buttons. JavaScript timers and event listeners were used to dynamically change the pictures for seamless navigation. Rotating photos with CSS transform improved the aesthetics of the gallery. This approach provided a captivating and visually attractive user experience. The gallery's visual richness could be appreciated with a three-second rotation interval and intuitive navigation buttons.",
        demo: 'https://dougbug98.github.io/Rotating-Image-Gallery/',
        github: 'https://github.com/dougbug98/Rotating-Image-Gallery'
    },

]


export default data