import { useState } from 'react';
import contacts from './data';
import './contact.css';

const Contact = () => {
  const [selectedContact, setSelectedContact] = useState(null);

  const handleContactClick = (contact) => {
    if (selectedContact === contact) {
      setSelectedContact(null);
    } else {
      setSelectedContact(contact);
    }
  }

  return (
    <section id="contact">
      <h2>Get In Touch</h2>
      <p>
        Shoot me a message via any of the links below! Click the phone icon to reveal my phone number!
      </p>
      <div className="container contact__container" data-aos="fade-up">
        {contacts.map(contact => (
          <a key={contact.id} href={contact.link} target="_blank" rel="noopener noreferrer" onClick={() => handleContactClick(contact)}>
            {contact.icon}
          </a>
        ))}
      </div>
      {selectedContact && (
        <p>+1-707-204-0797 {selectedContact.name}</p>
      )}
    </section>
  )
}

export default Contact


// To show text when a user clicks on an icon, you can add an onClick event handler to each <a> element that triggers a state change in your component.
// In this example, we're using the useState hook to keep track of which contact was clicked on, and the handleContactClick function to update the state 
// when a contact is clicked. We're also rendering a new <p> element below the contact icons to display the selected contact's name when it's clicked.
// To hide the selected contact when the user clicks on the icon again, you can modify the handleContactClick function to toggle the state of selectedContact. 
// In this example, we're checking if the clicked contact is the same as the currently selected contact. If it is, we set selectedContact to null to hide the selected contact. If it's not, we set selectedContact to the clicked contact to show the selected contact. This creates a toggle effect where clicking the same contact twice hides it again.