import { FaAward } from 'react-icons/fa'
import { TbBooks } from 'react-icons/tb'


const data = [
    { id: 1, icon: <FaAward />, title: 'Experience', desc: '5 Years' },
    { id: 2, icon: <TbBooks />, title: 'Projects', desc: '9 Projects Completed' },
]



export default data;